@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "/src/parameters";

html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
app-root {
  height: 100%;
}

div.content-wrapper {
  margin: 10px auto
}

.has-error {
  color: red;
}
.has-success {
  color: $base-color;
}

.btn-primary, .btn-primary:focus, .btn-primary:hover,.btn-outline-success:hover, .btn-outline-success:focus {
  background-color: $base-color !important;
  border-color: $base-color !important;
}
.form-control:focus, .btn-primary:focus, .btn-primary:hover, .btn-outline-success:hover, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgb(17, 157, 164, 0.5) !important;
}
.btn-outline-success {
  color: $base-color;
  border-color: $base-color;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.card-header-margin {
  margin-top: 0.2rem !important;
  margin-bottom: 0.2rem !important;
}

.toast-top-center {
  top: 12px;
  right: 50%;
  transform: translate(50%, 0)
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
